import {createRouter, createWebHistory} from 'vue-router'
const routerHistory = createWebHistory()

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  //主页
  {
    path: '/register',
    component: () => import('../views/register/index.vue'),
  },
  
  {
    path: '/home1',
    name: 'home1',
    component: () => import('../views/Home1.vue')
  },
  //完成
  {
    path: '/finish',
    component: () => import('../views/finish.vue'),
  },
  //页面选择
  {
    path: '/change',
    component: () => import('../views/change.vue'),
  },
  //报名页面
  {
    path: '/application',
    component: () => import('../views/application.vue'),
  },
  //支付
  {
    path: '/pay',
    component: () => import('../views/Pay/index.vue'),
  },
  //测试用页面

  {
    path: '/ceshi2',
    component: () => import('../views/register/ceshi2.vue'),
  },
  //支付成功
  {
    path: '/PaySuccess',
    component: () => import('../views/PaySuccess/index.vue'),
  },
  {
    path:'/',
    redirect:'/register',
  }
]

// 创建路由器
const router = createRouter({
  history: routerHistory,
  routes: routes
})

export default router