import { createStore } from 'vuex'

export default createStore({
  state: {
    token:'',
    openid:'o7zfh5MtVJG1pY5-6PygTwR41QmI',
    message:{}
  },

  mutations: {
    MESSAGE(state,message){
      state.message = message
    },
    OPENID(state,openid){
      state.openid = openid
    }
  },
  actions: {
  },

})
